@import url('https://fonts.googleapis.com/css2?family=Raleway');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative&display=swap');

.portfolio-page-container { 
    background-color: white;
    padding-left: var(--container-padding-left);
    padding-right: var(--container-padding-right);
    padding-top: var(--container-padding-top);
    padding-bottom: var(--container-padding-bottom);
    display: var(--container-display);
    gap: var(--container-gap);
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
}

.portfolio-page-section {
    display: var(--section-display);
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
}

.portfolio-page-section-summary {
    display: var(--section-display);
    gap: var(--section-gap);
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
}

.portfolio-page-section-left,
.portfolio-page-section-right {
    color: #000;
}

.portfolio-page-section-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-basis: 50%;
    gap: 1rem;
}

.portfolio-page-section-left h1 {
    width: 100%;
    font-family: var(--button-font-family);
    text-align: left;
    font-weight: lighter;
    font-size: var(--font-size-xxxlarge);
    line-height: 0.9em;
}

.portfolio-page-section-left p {
    font-family: var(--font-family-secondary);
    font-weight: 100;
    font-size: var(--font-size-large);
    text-align: justify;
    line-height: 1.75rem;
}

.portfolio-page-section-left p a {
    text-decoration: none;
    color: var(--tertiary-color);
}

.portfolio-page-section-left .portfolio-tags {
    justify-content: flex-start;
    gap: var(--spacing-medium);
}

.portfolio-page-section-left .portfolio-tags p {
    font-size: var(--font-size-medium);
}

.portfolio-page-section-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-basis: 50%;
}

.portfolio-page-section-right .figure {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
}

.portfolio-page-section-right .figure img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}


/* CONTENT */
.portfolio-page-section-one-column {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    font-family: var(--font-family-primary);
}

.portfolio-page-section-one-column p {
    font-size: var(--font-size-large);
    font-weight: lighter;
}

.portfolio-page-subsection-two-columns {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    gap: var(--sub-section-gap);
}

.portfolio-page-subsection-two-columns-column {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    gap: var(--sub-section-gap);
}

.portfolio-page-subsection-two-columns-column .figure {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.portfolio-page-subsection-two-columns-column img {
    width: 50%;
    height: 50%;
    object-fit: cover;
}

.portfolio-page-subsection-two-columns-column .font-sample {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.font-sample .text-h1 {
    font-family: "Cinzel Decorative", cursive;
    font-size: var(--font-size-xxxlarge);
}

.font-sample .text-h2 {
    font-family: "Playfair Display", serif;
    font-size: var(--font-size-xxlarge);
}

.font-sample .text-p {
    font-family: "Raleway", sans-serif;
    font-size: var(--font-size-large);
}

