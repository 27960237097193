@import url(https://fonts.googleapis.com/css2?family=Raleway);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cinzel+Decorative&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rowdies&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Urbanist&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Gravitas+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Serif+4&display=swap);
.header-container {
    padding-left: var(--spacing-xxlarge);
    padding-right: var(--spacing-xxlarge);
    padding-bottom: var(--spacing-medium);
    padding-top: var(--spacing-medium);
    background-color: #000;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.header-container a {
    text-decoration: none;
}

.header-container .logo {
    display: flex;
    flex-direction: row;
}

.header-container .logo p.char-delta {
    color: var(--primary-color);
    font-size: var(--font-size-xxxlarge);
    font-family: "Source Serif 4", serif;
    display: flex;
    align-items: last baseline;
}

.header-container .logo p.char-s {
    color: var(--primary-color);
    font-family: var(--font-family-header);
    font-size: var(--font-size-xxxlarge);
    display: flex;
    align-items: last baseline;
}

nav {
    display: none;
}

nav .hamburger {
    display: none;
}

@media (max-width: 1100px) {
    nav {
        display: flex;
        width: 100%;
    }

    nav .hamburger {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        position: absolute !important;
        top: 7px;
        right: 10px;
        width: 5rem;
        height: 5rem;
        cursor: pointer;
        padding: 1rem;
    }

    nav .hamburger:focus {
        outline: none;
    }

    .line {
        background-color: var(--primary-color);
        width: 100%;
        height: 0.3rem;
        border-radius: 0.2rem;
        transition: all 0.5s ease-in-out;
    }

    .clicked .line-1 {
        transform: rotateZ(-405deg) translate(-0.8rem, 0.6rem);
    }

    .clicked .line-2 {
        opacity: 0;
    }

    .clicked .line-3 {
        transform: rotateZ(405deg) translate(-0.8rem, -0.6rem);
    }

    nav ul.menu {
        display: none;
        flex-direction: column;
        width: 100%;
        transition: max-height 0.5s ease-in-out;
        overflow: hidden;
        max-height: 0rem;
    }

    nav ul.menu.visible {
        grid-gap: var(--mobile-spacing-small);
        gap: var(--mobile-spacing-small);
        display: flex;
        justify-content: flex-start;
        max-height: 13rem;
        width: 100%;
    }

    nav ul.menu.visible li {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
    }

    nav ul.menu.visible li button {
        color: var(--primary-color);
        font-family: var(--button-font-family);
        padding: var(--button-padding);
        font-size: var(--mobile-font-size-xlarge);
        padding: 0;
        background: none;
        border: none;
        cursor: pointer;
        text-decoration: none;
        width: 100%;
    }

    nav ul.menu.visible li button:hover {
        background-color: var(--primary-color);
        color: #000;
        text-decoration: none;
    }
}
.hero-container {
    padding-left: var(--container-padding-left);
    padding-right: var(--container-padding-right);
    padding-top: var(--container-padding-top);
    padding-bottom: var(--container-padding-bottom);
    display: var(--container-display);
    grid-gap: var(--container-gap);
    gap: var(--container-gap);
    background-color: #000;
    align-items: center;
    justify-content: space-between;
}

.hero-section-left,
.hero-section-right {
    color: white;
}

.hero-section-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-basis: 40%;
    grid-gap: 1rem;
    gap: 1rem;
}

.hero-section-left h1 {
    width: 100%;
    font-family: var(--button-font-family);
    text-align: left;
    font-weight: lighter;
    font-size: var(--font-size-xxxlarge);
    line-height: 0.9em;
}

.hero-section-left h2 {
    width: 100%;
    font-family: var(--font-family-primary);
    text-align: left;
    font-weight: 500;
    font-size: var(--font-size-xlarge);
}

.hero-section-left h3, .hero-section-left p {
    width: 100%;
    font-family: var(--font-family-secondary);
    text-align: left;
    font-weight: 100;
    font-size: var(--font-size-medium);
}

.hero-section-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-basis: 60%;
}

.hero-section-right .figure {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.hero-section-right .figure img {
    max-width: 33%;
    height: auto;
    object-fit: contain;
}

.hero-buttons {
    display: flex;
    flex-direction: row;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
}

.atendimento-button {
    background-color: var(--primary-color);
    padding: var(--button-padding);
    font-family: var(--button-font-family);
    /* border-radius: var(--button-border-radius); */
    text-transform: uppercase;
    font-weight: lighter;
    color: #000;
    border: none;
    cursor: pointer;
    font-size: 1.25rem;
    letter-spacing: 0.1em;
    flex-shrink: 0;
    flex-grow: 0;
    align-self: flex-start;
}

.atendimento-button:hover {
    background-color: #000;
    color: var(--primary-color);
}

.saiba-mais {
    background-color: #444;
    padding: var(--button-padding);
    font-family: var(--button-font-family);
    /* border-radius: var(--button-border-radius); */
    text-transform: uppercase;
    font-weight: lighter;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 1.25rem;
    letter-spacing: 0.1em;
    flex-shrink: 0;
    flex-grow: 0;
    align-self: flex-start;
}

.saiba-mais:hover {
    background-color: #ffffff;
    color: #444;
}

@media (max-width: 1450px) {
    .atendimento-button, .saiba-mais {
        font-size: var(--mobile-font-size-normal);
    }

    .hero-buttons {
        justify-content: space-evenly;
    }
}


@media (max-width: 1340px) {
    .hero-container {
        display: var(--mobile-container-display);
        flex-basis: var(--mobile-container-flex-basis);
        flex-direction: var(--mobile-container-flex-direction);
        position: relative;
        flex-direction: column;
    }
}

@media (max-width: 800px) {
    .hero-container {
        display: var(--mobile-container-display);
        flex-basis: var(--mobile-container-flex-basis);
        flex-direction: var(--mobile-container-flex-direction);
        position: relative;
        flex-direction: column;
        padding: 0;
    }

    .hero-section-left {
        grid-gap: var(--mobile-spacing-small);
        gap: var(--mobile-spacing-small);
        display: flex;
        flex-direction: column;
        padding: 1rem;
    }

    .hero-section-left h1 {
        font-size: var(--mobile-font-size-xxlarge);
    }
    
    .hero-section-left h2 {
        font-size: var(--mobile-font-size-large);
    }
    
    .hero-section-left h3, .hero-section-left p {
        font-size: var(--mobile-font-size-normal);
    }

    .hero-buttons {
        grid-gap: var(--mobile-spacing-small);
        gap: var(--mobile-spacing-small);
        width: 100%;
        padding-top: 2rem;
    }

    .atendimento-button, .saiba-mais {
        font-size: var(--mobile-font-size-xlarge);
    }
}

@media (max-width: 600px) {
    .atendimento-button, .saiba-mais {
        font-size: var(--mobile-font-size-small);
    }

    .hero-buttons {
        justify-content: space-evenly;
    }
}
.portfolio-page-container { 
    background-color: white;
    padding-left: var(--container-padding-left);
    padding-right: var(--container-padding-right);
    padding-top: var(--container-padding-top);
    padding-bottom: var(--container-padding-bottom);
    display: var(--container-display);
    grid-gap: var(--container-gap);
    gap: var(--container-gap);
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
}

.portfolio-page-section {
    display: var(--section-display);
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
}

.portfolio-page-section-summary {
    display: var(--section-display);
    grid-gap: var(--section-gap);
    gap: var(--section-gap);
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
}

.portfolio-page-section-left,
.portfolio-page-section-right {
    color: #000;
}

.portfolio-page-section-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-basis: 50%;
    grid-gap: 1rem;
    gap: 1rem;
}

.portfolio-page-section-left h1 {
    width: 100%;
    font-family: var(--button-font-family);
    text-align: left;
    font-weight: lighter;
    font-size: var(--font-size-xxxlarge);
    line-height: 0.9em;
}

.portfolio-page-section-left p {
    font-family: var(--font-family-secondary);
    font-weight: 100;
    font-size: var(--font-size-large);
    text-align: justify;
    line-height: 1.75rem;
}

.portfolio-page-section-left p a {
    text-decoration: none;
    color: var(--tertiary-color);
}

.portfolio-page-section-left .portfolio-tags {
    justify-content: flex-start;
    grid-gap: var(--spacing-medium);
    gap: var(--spacing-medium);
}

.portfolio-page-section-left .portfolio-tags p {
    font-size: var(--font-size-medium);
}

.portfolio-page-section-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-basis: 50%;
}

.portfolio-page-section-right .figure {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
}

.portfolio-page-section-right .figure img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}


/* CONTENT */
.portfolio-page-section-one-column {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    font-family: var(--font-family-primary);
}

.portfolio-page-section-one-column p {
    font-size: var(--font-size-large);
    font-weight: lighter;
}

.portfolio-page-subsection-two-columns {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    grid-gap: var(--sub-section-gap);
    gap: var(--sub-section-gap);
}

.portfolio-page-subsection-two-columns-column {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    grid-gap: var(--sub-section-gap);
    gap: var(--sub-section-gap);
}

.portfolio-page-subsection-two-columns-column .figure {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.portfolio-page-subsection-two-columns-column img {
    width: 50%;
    height: 50%;
    object-fit: cover;
}

.portfolio-page-subsection-two-columns-column .font-sample {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.font-sample .text-h1 {
    font-family: "Cinzel Decorative", cursive;
    font-size: var(--font-size-xxxlarge);
}

.font-sample .text-h2 {
    font-family: "Playfair Display", serif;
    font-size: var(--font-size-xxlarge);
}

.font-sample .text-p {
    font-family: "Raleway", sans-serif;
    font-size: var(--font-size-large);
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 16px;
    scroll-behavior: smooth;
    background-color: #000;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    /* overflow-x: hidden; */
}

:root {
    /* colors */
    --primary-color: #FFDB58;
    --secondary-color: #00E0FF;
    --tertiary-color: #FE59FF;
    /* font sizes */
    --font-size-tiny: 0.5rem;
    --font-size-small: 0.75rem;
    --font-size-medium: 1rem;
    --font-size-large: 1.25rem;
    --font-size-xlarge: 1.5rem;
    --font-size-xxlarge: 2rem;
    --font-size-xxxlarge: 3rem;
    /* font families */
    --font-family-header: 'Gravitas One', cursive;
    --font-family-primary: 'Urbanist', sans-serif;
    --font-family-secondary: 'Urbanist', sans-serif;
    --button-font-family: 'Rowdies', cursive;
    /* spacing */
    --spacing-small: 0.5rem;
    --spacing-medium: 1rem;
    --spacing-large: 2rem;
    --spacing-xlarge: 4rem;
    --spacing-xxlarge: 8rem;
    --font-line-height: 1.5rem;
    /* breakpoints */
    --breakpoint-small: 576px;
    --breakpoint-medium: 768px;
    --breakpoint-large: 992px;
    --breakpoint-xlarge: 1200px;
    /* images */
    --icon-size-small: 1.5rem;
    --icon-size-medium: 2rem;
    --icon-size-large: 3rem;
    --icon-size-xlarge: 4rem;
    /* button */
    --button-border-radius: 0.5rem;
    --button-padding: 8px 15px 8px 15px;
    --button-font-size: 1.5rem;
    /* container */
    --container-display: flex;
    --container-gap: 3rem;
    --container-padding-left: 6rem;
    --container-padding-right: 6rem;
    --container-padding-top: 2rem;
    --container-padding-bottom: 2rem;
    /* section */
    --section-display: flex;
    --section-gap: 2rem;
    /* sub-section */
    --sub-section-display: flex;
    --sub-section-gap: 2rem;
    /* mobile */
    --mobile-container-display: flex;
    --mobile-container-flex-direction: column;
    --mobile-container-flex-basis: 100%;
    --mobile-container-padding: 1rem; 
    /* mobile section */
    --mobile-section-display: flex;
    --mobile-section-gap: 2rem;
    --mobile-section-padding: 1rem;
    /* mobile sub-section */
    --mobile-sub-section-display: flex;
    --mobile-sub-section-gap: 1rem;
    /* mobile spacing */
    --mobile-spacing-small: 0.5rem;
    --mobile-spacing-medium: 1rem;
    --mobile-spacing-large: 2rem;
    --mobile-spacing-xlarge: 3rem;
    /* mobile font sizes */
    --mobile-font-size-small: 0.75rem;
    --mobile-font-size-medium: 0.9rem;
    --mobile-font-size-large: 1.25rem;
    --mobile-font-size-xlarge: 1.5rem;
    --mobile-font-size-xxlarge: 2rem;
}
