.hero-container {
    padding-left: var(--container-padding-left);
    padding-right: var(--container-padding-right);
    padding-top: var(--container-padding-top);
    padding-bottom: var(--container-padding-bottom);
    display: var(--container-display);
    gap: var(--container-gap);
    background-color: #000;
    align-items: center;
    justify-content: space-between;
}

.hero-section-left,
.hero-section-right {
    color: white;
}

.hero-section-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-basis: 40%;
    gap: 1rem;
}

.hero-section-left h1 {
    width: 100%;
    font-family: var(--button-font-family);
    text-align: left;
    font-weight: lighter;
    font-size: var(--font-size-xxxlarge);
    line-height: 0.9em;
}

.hero-section-left h2 {
    width: 100%;
    font-family: var(--font-family-primary);
    text-align: left;
    font-weight: 500;
    font-size: var(--font-size-xlarge);
}

.hero-section-left h3, .hero-section-left p {
    width: 100%;
    font-family: var(--font-family-secondary);
    text-align: left;
    font-weight: 100;
    font-size: var(--font-size-medium);
}

.hero-section-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-basis: 60%;
}

.hero-section-right .figure {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.hero-section-right .figure img {
    max-width: 33%;
    height: auto;
    object-fit: contain;
}

.hero-buttons {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.atendimento-button {
    background-color: var(--primary-color);
    padding: var(--button-padding);
    font-family: var(--button-font-family);
    /* border-radius: var(--button-border-radius); */
    text-transform: uppercase;
    font-weight: lighter;
    color: #000;
    border: none;
    cursor: pointer;
    font-size: 1.25rem;
    letter-spacing: 0.1em;
    flex-shrink: 0;
    flex-grow: 0;
    align-self: flex-start;
}

.atendimento-button:hover {
    background-color: #000;
    color: var(--primary-color);
}

.saiba-mais {
    background-color: #444;
    padding: var(--button-padding);
    font-family: var(--button-font-family);
    /* border-radius: var(--button-border-radius); */
    text-transform: uppercase;
    font-weight: lighter;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 1.25rem;
    letter-spacing: 0.1em;
    flex-shrink: 0;
    flex-grow: 0;
    align-self: flex-start;
}

.saiba-mais:hover {
    background-color: #ffffff;
    color: #444;
}

@media (max-width: 1450px) {
    .atendimento-button, .saiba-mais {
        font-size: var(--mobile-font-size-normal);
    }

    .hero-buttons {
        justify-content: space-evenly;
    }
}


@media (max-width: 1340px) {
    .hero-container {
        display: var(--mobile-container-display);
        flex-basis: var(--mobile-container-flex-basis);
        flex-direction: var(--mobile-container-flex-direction);
        position: relative;
        flex-direction: column;
    }
}

@media (max-width: 800px) {
    .hero-container {
        display: var(--mobile-container-display);
        flex-basis: var(--mobile-container-flex-basis);
        flex-direction: var(--mobile-container-flex-direction);
        position: relative;
        flex-direction: column;
        padding: 0;
    }

    .hero-section-left {
        gap: var(--mobile-spacing-small);
        display: flex;
        flex-direction: column;
        padding: 1rem;
    }

    .hero-section-left h1 {
        font-size: var(--mobile-font-size-xxlarge);
    }
    
    .hero-section-left h2 {
        font-size: var(--mobile-font-size-large);
    }
    
    .hero-section-left h3, .hero-section-left p {
        font-size: var(--mobile-font-size-normal);
    }

    .hero-buttons {
        gap: var(--mobile-spacing-small);
        width: 100%;
        padding-top: 2rem;
    }

    .atendimento-button, .saiba-mais {
        font-size: var(--mobile-font-size-xlarge);
    }
}

@media (max-width: 600px) {
    .atendimento-button, .saiba-mais {
        font-size: var(--mobile-font-size-small);
    }

    .hero-buttons {
        justify-content: space-evenly;
    }
}